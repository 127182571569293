$purple: #b44afb;

@font-face {
  font-family: "Fredoka";
  src: url('./styles/fonts/Fredoka.ttf');
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Fredoka', sans-serif;
  color: #030303;
}

h1, h2, h3, h4, h5, b {
  font-weight: 500;
}

#gateway {
  padding: 20px;
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;

  nav {
    height: 60px;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 20px);
    background: none;
    transition: 0.2s;
    z-index: 3;

    #navLogo {
      position: absolute;
      top: 10px;
      left: calc(50% - 30px);
      height: 60px;
    }

    #openNav,
    #closeNav {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 30px;
      color: white;
      padding: 25px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      opacity: 0;
      rotate: 90deg;
      transition: 0.2s;
      cursor: pointer;
    }

    #closeNav {
      rotate: -90deg;
    }

    #openNav.active,
    #closeNav.active {
      z-index: 3;
      opacity: 1;
      rotate: 0deg;
    }

    .sideNav {
      width: 0px;
      height: calc(100vh - 80px);
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      top: 80px;
      left: 0;
      transition: 0.2s;
      background-color: white;
      text-align: left;
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      a {
        padding: 20px;
        text-decoration: none;
        font-size: 20px;
        color: black;
        display: block;
        transition: 0.2s;

        &:hover {
          color: $purple;
        }

        &:first-child {
          margin-top: 20px;
        }
      }
    }

    .sideNavActive {
      width: 300px;
      z-index: 3;
      position: fixed;
    }

    #dashboardBtn {
      width: 150px;
      padding: 10px;
      margin: 10px 20px;
      background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
      text-align: center;
      color: white;
      border-radius: 5px;
    }
  }

  .navWhite {
    background-color: white;
    color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;

    #openNav,
    #closeNav {
      color: $purple;
    }

    img {
      filter: invert(50%) sepia(72%) saturate(5923%) hue-rotate(255deg) brightness(100%) contrast(98%)
    }
  }

  #tint {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  #navBG {
    display: none;
  }

  #landing {
    background-image: url('./assets/background.png');
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -20px;
    margin-left: -20px;
    padding: 30px;
    padding-top: 100px;
    box-sizing: border-box;

    h1 {
      font-size: 11vw;
      letter-spacing: 1vw;
      color: white;
      margin: 0;
      margin-bottom: 30px;
    }

    p {
      font-size: 5.2vw;
      color: white;
      margin: 0;
      margin-bottom: 50px;
      line-height: 1.5;
    }

    a {
      padding: 12px 30px;
      background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
      text-align: center;
      color: white;
      border-radius: 5px;
      text-decoration: none;
      font-size: 6vw;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    }
  }

  #features {
    h1 {
      margin: 50px 0 20px;
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 20px;

      span {
        border: solid 1px #c1c1c1;
        border-radius: 5px;
        padding: 20px 10px;
        margin: 10px 0;
      }
    }

    b {
      display: inline-block;
      margin: 0 15px;
    }

    p {
      font-size: 1.1rem;
      margin: 0;
      line-height: 1.5;
    }

    a {
      font-size: 5.2vw;
      margin: 0;
      margin-bottom: 50px;
      line-height: 1.5;
      text-decoration: none;
      padding: 3vw 5vw;
      border: solid 1px black;
      border-radius: 5px;
      color: #5b4afb;
      text-decoration: none;
    }
  }

  #demoImg {
    width: 120vw;
    margin: 100px 0;
    margin-left: -20px;
    overflow-x: hidden;
  }

  #solutions {
    margin-bottom: 100px;

    li {
      font-size: 7vw;
      font-weight: normal;
      position: relative;
      padding: 25px 10px;
      list-style-type: square;

      i {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 5.2vw;
        color: #c1c1c1;
        font-weight: normal;
      }
    }

    a {
      font-size: 5.2vw;
      margin: 0;
      text-decoration: none;
      padding: 3vw 5vw;
      border-radius: 5px;
      background: #5b4afb;
      color: white;
      text-decoration: none;
    }
  }

  #contact {
    input {
      padding: 10px;
      box-sizing: border-box;
      font-family: 'Fredoka';
    }

    button {
      font-family: 'Fredoka';
      font-size: 5.2vw;
      margin: 0;
      margin-bottom: 50px;
      text-decoration: none;
      padding: 2vw 7vw;
      border: none;
      border-radius: 5px;
      color: white;
      background: #5b4afb;
      text-decoration: none;
    }
  }
}

@media only screen and (min-width: 400px) {
  #gateway #landing {
    text-align: center;

    h1 {
      font-size: 40px;
      letter-spacing: 5px;
    }

    p {
      font-size: 20px;
    }

    a {
      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 700px) {
  #background {
    background-image: url('./assets/background-wide.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 700px;
    position: absolute;
    top: 0;
    left: 0;
  }

  #navBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    background: #b44afb;
  }

  #gateway {
    max-width: 1200px;
    margin: auto;
    position: relative;

    nav {
      display: flex;
      justify-content: right;
      max-width: 1180px;

      #navLogo {
        left: 30px;
      }

      a {
        padding: 20px 15px;
        font-size: 17px;
        text-decoration: none;
        color: white;

        &:hover {
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        }
      }

      #dashboardBtn {
        width: unset;
        padding: 10px 20px;
        text-decoration: none;

        &:hover {
          text-shadow: none;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        }
      }
    }

    #landing {
      width: unset;
      background-image: unset;
      height: 700px;

      h1 {
        margin-top: 50px;
        font-size: 60px;
        letter-spacing: 7px;
      }

      p {
        font-size: 20px;
        max-width: 770px;
        margin: auto;
      }

      a.largeBtn {
        display: inline-block;
        margin-top: 70px;
        font-size: 20px;

        &:hover {
          background: white;
          color: #5b4afb;
        }
      }
    }

    #features {
      h1 {
        margin: 100px 0 50px;
      }

      div {
        flex-direction: row;
  
        span {
          padding: 30px;
          box-sizing: border-box;
          width: 30vw;
          max-width: 320px;
        }
      }
  
      a {
        font-size: 17px;
        padding: 10px 30px;
        margin-top: 50px;
        display: inline-block;

        &:hover {
          color: white;
          background-color: #5b4afb;
          border: solid 1px #5b4afb;
        }
      }
    }
  
    #demoImg {
      width: 80vw;
      max-width: 800px;
      margin: 100px auto;
      display: block;
    }
  
    #solutions {
      margin-bottom: 100px;

      ul {
        max-width: 600px;
        margin: auto;
      }
  
      li {
        font-size: 25px;
  
        i {
          font-size: 22px;
        }
      }
  
      a {
        font-size: 17px;
        padding: 10px 30px;
        margin-top: 50px;
        display: inline-block;

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        }
      }
    }
  
    #contact {
      max-width: 500px;
      margin: auto;
  
      button {
        font-size: 17px;
        padding: 10px 30px;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}