#footer {
  background-color: #f5f5f5;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  margin-left: -20px;
  margin-bottom: -20px;
  text-align: center;
  font-size: 14px;
  color: #777;

  a {
    color: #555;
  }

  #footerLinks {
    padding: 30px;
    text-align: left;
  }

  #footerBottom {
    padding: 20px;
    border-top: solid 1px black;

    #socials {
      margin-top: 30px;
    }

    a {
      padding: 20px;
    }
  }
}

#footerBG {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 321px;
  margin-top: -321px;
  background: #f5f5f5;
  z-index: -1;
  display: none;
}

@media only screen and (min-width: 700px) {
  #footerBG {
    display: block;
  }

  #footer {
    max-width: 1200px;
  }
}