.dashboard {
  background: #b44afb;

  #dashboard {
    background: white;
    margin-top: 60px;
    margin-left: -20px;
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    padding: 1px 20px;

    h2 {
      font-size: 22px;
      margin-top: 50px;
    }

    .border {
      border-top: solid 1px #f5f5f5;
      border-bottom: solid 1px #f5f5f5;
    }

    .section {
      margin-top: 20px;
      margin-bottom: 20px;

      h3 {
        margin-bottom: 5px;
        margin-top: 60px;
      }

      input {
        width: calc(100vw - 149.7px);
        max-width: 400px;
        padding: 10px;
        margin: 15px 15px 15px 0;
        box-sizing: border-box;
        border: solid 1px #f5f5f5;
        border-radius: 5px;
      }

      #saveBtn {
        width: 74.7px;
        padding: 10px 20px;
      }
    }

    #revealKey {
      margin-left: 50px;
      display: inline-block;
      cursor: pointer;
    }

    a,
    button,
    .button {
      font-size: 15px;
      margin: 0;
      text-decoration: none;
      padding: 5px 20px;
      border-radius: 5px;
      background: #5b4afb;
      color: white;
      text-decoration: none;
      border: none;
      min-height: unset;
      cursor: pointer;
    }

    #upgradeBtn {
      background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
    }

    #changeWebhookButtons, 
    #newTokenButtons {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;

      a {
        margin-left: 20px;
        background: none;
        color: #5b4afb;
      }
    }

    #createPaymentBtn {
      position: fixed;
      width: 320px;
      height: 500px;
      top: calc(50vh - 250px);
      left: calc(50vw - 160px);
      background: white;
      border-radius: 5px;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1000;
    }

    #createPaymentBtn h3 {
      margin-top: 10px;
    }

    #createPaymentBtn input {
      width: 100%;
      margin: 10px 0;
    }

    button {
      cursor: pointer;
    }

    .paymentButton {
      padding: 20px;
      border-radius: 5px;
      background: #f5f5f5;
      margin: 20px 0;

      p {
        margin-top: 0;
      }

      .paymentButtonInteractions {
        display: flex;
        align-items: center;

        button {
          background: none;
          color: #5b4afb;
          border: solid 1px #5b4afb;
        }

        a {
          color: #5b4afb;
          background: none;
          border: none;
        }
      }

      .renderTest {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        margin-top: -10px;
      }

      .testMode {
        background: repeating-linear-gradient(45deg,
            yellow,
            yellow 8px,
            black 8px,
            black 16px,
            yellow 16px);
        height: 5px;
        width: calc(50% - 40px);
      }
    }

    .sandboxPaymentBtn {
      background: #fbb44a1f;
    }

    #buttonPaymentCode {
      position: fixed;
      width: 320px;
      height: 500px;
      top: calc(50vh - 250px);
      left: calc(50vw - 160px);
      background: white;
      border-radius: 5px;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1000;
      
      h3 {
        margin-top: 10px;
      }

      #codeSnippet {
        width: 100%;
        overflow-x: auto;
        margin: 10px 0;
        padding: 10px;
        box-sizing: border-box;
        background: #f5f5f5;
        border-radius: 5px;
        font-size: 15px;
      }
    }

    .history {
      max-height: 70vh;
      overflow: auto;
    }

    .historyStyleLive,
    .historyStyleTest {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 5px;
      background: #f5f5f5;
      font-size: 15px;
      text-decoration: none;
      border: none;
      min-height: unset;

      .renderTest {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        margin-top: -10px;
      }

      .testMode {
        background: repeating-linear-gradient(45deg,
            yellow,
            yellow 8px,
            black 8px,
            black 16px,
            yellow 16px);
        height: 10px;
        width: calc(50% - 40px);
      }
    }

    .historyStyleTest {
      background: #fbb44a1f;
    }

    #withdrawal {
      position: fixed;
      width: 320px;
      height: 300px;
      top: calc(50vh - 150px);
      left: calc(50vw - 160px);
      background: white;
      border-radius: 5px;
      padding: 20px;
      box-sizing: border-box;
      z-index: 1000;
    
      h2, h3 {
        margin-top: 10px;
      }
    
      input {
        width: 280px;
        margin: 10px 0;
      }
      
      .completeImg {
        display: block;
        margin: auto;
        width: 120px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .dashboard #dashboard {
    width: 700px;
    padding: 20px calc(50vw - 350px) 100px;
    box-sizing: unset;

    .historyStyleTest .testMode {
      max-width: 300px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .dashboard #dashboard {
    width: 700px;
    padding: 20px 250px 100px;
  }
}