.register {
  background-color: #b44afb;

  #register {
    min-height: calc(100vh - 380px);
    background-color: white;
    width: 100vw;
    margin-top: 60px;
    margin-left: -20px;
    padding: 20px;
    box-sizing: border-box;

    .step {
      padding: 10px;
      box-sizing: border-box;

      h4 {
        margin-bottom: 0;
      }

      i {
        display: block;
        font-size: 12px;
        margin-top: 10px;
      }

      form {
        width: calc(100% - 10px);
        margin-bottom: 100px;
        margin-top: 30px;

        input {
          width: 100%;
          margin-bottom: 10px;
          padding: 10px;
          box-sizing: border-box;
          font-family: 'Fredoka';
          border: solid 1px #c1c1c1;
          border-radius: 5px;
        }

        #business {
          width: unset;
          margin: 30px 10px;
        }

        button,
        a {
          padding: 7px 20px;
          background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
          text-align: center;
          color: white;
          border-radius: 5px;
          border: none;
          font-family: 'Fredoka';
          box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
        }
      }

      #dashboardLink {
        display: inline-block;
        text-decoration: none;
        padding: 7px 20px;
        background: linear-gradient(to bottom right, #5b4afb, #6FA8FC);
        text-align: center;
        color: white;
        border-radius: 5px;
        border: none;
        font-family: 'Fredoka';
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
        margin-bottom: 100px;
      }

    }
  }
}

@media only screen and (min-width: 700px) {
  .register {
    #register {
      max-width: 1200px;
      display: block;
    }
  }

  .registerProcess {
    width: 700px;
    display: block;
    margin: auto;
    margin-top: 60px;

    .step {
      width: 600px;
      display: block;
      margin: auto;
    }

    button {
      cursor: pointer;
    }
  }
}