#pricing {
  min-height: 100vh;
  margin-top: 60px;
  width: 100vw;
  margin-left: -20px;
  padding: 20px;
  background: white;

  h1 {
    text-align: center;
    width: calc(100vw - 40px);
  }
  
  #notice {
    display: block;
     width: calc(100vw - 40px);
     margin-bottom: 20px;
     font-size: 12px;
  }

  .plan {
    text-align: center;
    border-radius: 5px;
    border: solid 1px #c1c1c1;
    width: calc(100vw - 40px);
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;

    .planOverview {
      text-align: left;
      margin-top: 30px;
    }

    a {
      display: block;
      margin: 0 auto;
      margin-top: 30px;
      padding: 10px 20px;
      border-radius: 5px;
      text-decoration: none;
      color: white;
      background-color: #5b4afb;
      font-size: 1rem;
    }
  }

  #premium {
    background: linear-gradient(to bottom right, grey, white);
  }

  #custom {
    background: linear-gradient(to bottom right, #a424fa, #be61fc);
    color: white;
    border: none;
  }
}

@media only screen and (min-width: 700px) {
  #pricing {
    width: 100vw;
    max-width: 1200px;

    h1 {
      max-width: 1200px;
    }

    #notice {
      max-width: 1200px;
      text-align: center;
      font-size: 13px;
    }

    #plans {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 50px;

      .plan {
        max-width: 400px;
        margin: 40px;
      }
    }
  }
}